<template>
  <div class="association">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <header class="header">
        <img src="./img/association.png" alt="图片加载失败..." />
      </header>
      <div class="tabTitle">
        <div class="title">社群列表</div>
        <div class="imgBox" @click="toRank()"><img src="./img/tag.png" /></div>
      </div>
      <div class="main">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="item-l"><img :src="item.picture" alt="" /></div>
            <div class="item-r">
              <div class="title">{{ item.associationName }}</div>
              <div class="des">{{ item.keyword }}</div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getAssociaListUrl } from "./api.js";
import { gloabalCount } from "@/utils/common";
import { handleImg } from "@/utils/utils";
export default {
  name: "association",
  components: {},
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    gloabalCount("", 24, 1);
  },
  methods: {
    toRank() {
      this.$router.push({
        name: "pointRankList",
        query: { id: "2" },
      });
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/associationDetail/index?params=${JSON.stringify(
          params
        )}`,
      });
    },
    // 加载列表数据
    onLoad() {
      this.getAssociationList();
    },
    onRefresh() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    getAssociationList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        associationId: "",
        parentId: "",
        associationLevel: 1,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${getAssociaListUrl}`, { params: params })
        .then((res) => {
          this.isLoading = false;
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.picture) {
                item.picture = handleImg(204, 204, item.picture);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.association {
  header {
    height: 420px;
    img {
      height: 100%;
    }
  }
  .tabTitle {
    width: 100%;
    height: 90px;
    position: relative;
    .title {
      font-size: 32px;
      font-weight: 600;
      color: #000;
      position: absolute;
      top: 50%;
      left: 32px;
      transform: translateY(-50%);
    }
    .imgBox {
      width: 282px;
      height: 52px;
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .main {
    padding: 32px;
    padding-top: 0;
    .item {
      height: 204px;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      display: flex;
      margin-bottom: 32px;
      .item-l {
        width: 204px;
        height: 204px;
        border-radius: 16px 0 0 16px;
        margin-right: 32px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 16px 0 0 16px;
        }
      }
      .item-r {
        flex: 1;
        box-sizing: border-box;
        padding: 32px 32px 0 0;
        .title {
          font-size: 32px;
          margin-bottom: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }
        .des {
          font-size: 28px;
          font-weight: 400;
          color: #97999b;
          line-height: 40px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
